export enum ERoutes  {
    HOME = '/',
    WORK_LIFE = '/work-life',
    SEHBEREICH = '/sehbereich',
    MATRIX = '/matrix',
    BRECHUNGSINDEX = '/brechungsindex',
    BESCHICHTUNGEN = '/beschichtungen',
    GLEITSICHT_TABELLE= '/gleitsicht_tabelle',
    ARBEITSPLATZ_TABELLE = '/arbeitsplatz_tabelle',
    EINSTAERKEN_TABELLE = '/einstaerken_tabelle'
}

export interface ITableItem {
    title: string,
    price?: string,
}

export enum EMatrix {
    NONE='none',
    G2 = 'g2',
    G3= 'g3',
    G4 = 'g4',
    G5 = 'g5',
    G6 = 'g6',
}

export enum EAufschlagPreise {
    MDR = 30,
    ANTISTATIC = 24,
    PHOTOTROP = 144,
    VERLAUFSFARBEN = 72,
    STANDARTFARBEN = 48,
    STAMMKUNDENRABATT = -30,
    PRISMA = 96,
    POLARISIEREND = 144,
    SPH = 48,
    CYL = 48,
    LOTUSBESCHICHTUNG = -30,
    MONTAGE = 25,
}

export interface ICalculationItem {
    label: string, 
    price: number | EAufschlagPreise,
}

export interface ITableCallbacks {
    setRaten: (rate: number) => void,
    setSelected: (rowIndex: number, columnIndex: number) => void,
    setPrice: (price: number) => void,
}

export enum ETableEntity {
    GLEITSICHT,
    ARBEITSPLATZ,
    EINSTAERKEN,
}