import { makeStyles, AppBar, Toolbar, IconButton, Typography, createStyles, MenuItem } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { ERoutes } from "../definitions/app.definitions";
import { useCustomAnchorHook } from "../utils/hooks.utils";
import CustomMenu from "./CustomMenu";
import IconFactory, { EIcons } from "./IconFactory";

const useStyles = makeStyles((theme: any) =>
 createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

const CustomAppBar: React.FC = () => {
    const history = useHistory();
    const classes = useStyles();
    const {anchorEl, closeMenu, openMenu} = useCustomAnchorHook();

    return (
        <>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} onClick={(event: any) => openMenu(event)}>
                        {IconFactory.getIconComponent(EIcons.Menu)}
                    </IconButton>
                    <Typography variant="h6" >
                        Augenglück App
                    </Typography>
                </Toolbar>
            </AppBar>
            <CustomMenu anchorEl={anchorEl} closeMenu={closeMenu} openMenu={openMenu}>
                <MenuItem onClick={() => history.push(ERoutes.HOME)}>Start</MenuItem>
                <MenuItem onClick={() => history.push(ERoutes.WORK_LIFE)}>Work-Life</MenuItem>
                <MenuItem onClick={() => history.push(ERoutes.SEHBEREICH)}>Sehbereiche</MenuItem>
                <MenuItem onClick={() => history.push(ERoutes.MATRIX)}>Matrix-Vergleich</MenuItem>
                <MenuItem onClick={() => history.push(ERoutes.BRECHUNGSINDEX)}>Berechnungsindex</MenuItem>
                <MenuItem onClick={() => history.push(ERoutes.BESCHICHTUNGEN)}>Beschichtungen</MenuItem>
                <MenuItem style={{marginTop: 16}} onClick={() => history.push(ERoutes.GLEITSICHT_TABELLE)}>Gleitsicht Tabelle</MenuItem>
                <MenuItem onClick={() => history.push(ERoutes.ARBEITSPLATZ_TABELLE)}>Arbeitsplatz</MenuItem>
                <MenuItem onClick={() => history.push(ERoutes.EINSTAERKEN_TABELLE)}>Einstärken</MenuItem>
            </CustomMenu>
        </>
    )
}

export default CustomAppBar;