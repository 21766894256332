import React from "react";
import CustomImage from "../../components/CustomImage";
import CustomHeadline from "../../components/CustomTypography/CustomHeadline";

const BrechungsIndexPage = () => {

    return (
        <div style={{marginTop: 16}}>
            <CustomHeadline>Der Brechungsindex-Präzisions-Geschliffene Brillengläser für mehr Ästhetik und Tragekomfort</CustomHeadline>
            <div style={{position: 'relative', width: '100%', height: '100%'}}>
                <CustomImage style={{width: '90%', marginTop: 16,}} path={'BrechungsIndex.jpg'}/>

                <div style={{position:'absolute', left: '8%', top: '45%', width: '50%'}}>
                    <CustomImage path={'BrechungsIndexLinsen.png'}/>
                    <CustomImage style={{width: '93%'}} path={'BrechungsIndexText.png'}/>
                </div>
            </div>
        </div>
    )
}

export default BrechungsIndexPage;