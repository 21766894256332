import { createStyles, makeStyles } from "@material-ui/core";
import { ETableEntity, ITableCallbacks } from "../../definitions/app.definitions";
import { ORANGE } from "../../theme/theme";
import CustomTableBody from "./CustomTableBody";

interface IProps {
    columns: string[],
    description: string[],
    items: any,
    callbacks: ITableCallbacks,
    tableEntity: ETableEntity,
}

const useStyles = makeStyles(
    createStyles({
        table: {
            display: 'flex', 
            flexDirection: 'column', 
            height: '100%', 
        },
        header: {
            flex: 1, 
            backgroundColor: ORANGE,
            color: 'white', 
            borderTop: '1px solid grey',
            display: 'flex',
            justifyContent: 'center', 
            textAlign: 'center', 
            alignItems: 'center',
            paddingTop: 16,
            paddingBottom: 16,
        },
        headerRow: {
            display: 'flex', 
            flex: 1,  
            maxHeight: '10%', 
            justifyContent: 'center'
        },
        initialHeaderDiv: {
            flex:1, 
            minWidth: 127
        }

    })
)

const CustomTable:React.FC<IProps> = props => {
    const styles = useStyles();
    const headers = props.columns.map((columnString, index) => (
        <div key={`header-${index}`} className={styles.header}>
            {columnString}
        </div>
    ))

    return (
        <div className={styles.table}>
            <div className={styles.headerRow}>
                <div className={styles.initialHeaderDiv} />
                {headers}
            </div>
            <CustomTableBody 
                tableEntity={props.tableEntity} 
                callbacks={props.callbacks} 
                columnCount={props.columns.length} 
                items={props.items} 
                descriptions={props.description} 
            />
        </div>
    )
}

export default CustomTable