import React from 'react';
import './App.css';
import CustomAppBar from './components/CustomAppBar';
import { ThemeProvider } from '@material-ui/core';
import { theme } from './theme/theme';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ERoutes } from './definitions/app.definitions';
import MatrixPage from './pages/MatrixPage/MatrixPage';
import SehbereichPage from './pages/SehbreichePage/SehbereichPage';
import StartPage from './pages/StartPage.tsx/StartPage';
import WorkLifePage from './pages/WorkLifePage/WorkLifePage';
import BrechungsIndexPage from './pages/BerechnungsIndexPage/BrechungsIndexPage';
import BeschichtungsPage from './pages/BeschichtungPage/BeschichtungsPage';
import GleitsichtTabelle from './pages/GleitsichtTabelle/GleitsichtTabelle';
import ArbeitsplatzPage from './pages/ArbeitsplatzPage/ArbeitsplatzPage';
import EinstaerkenPage from './pages/EinstaerkenPage/EinstaerkenPage';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <CustomAppBar />
          <div style={{marginTop: 16, height: '100%', width: '100%'}}>
            <Switch>
                  <Route exact path={ERoutes.HOME} component={StartPage} />
                  <Route path={ERoutes.WORK_LIFE} component={WorkLifePage} />
                  <Route path={ERoutes.SEHBEREICH} component={SehbereichPage}/>
                  <Route path={ERoutes.MATRIX} component={MatrixPage}/>
                  <Route path={ERoutes.BRECHUNGSINDEX} component={BrechungsIndexPage}/>
                  <Route path={ERoutes.BESCHICHTUNGEN} component={BeschichtungsPage}/>
                  
                  <Route path={ERoutes.GLEITSICHT_TABELLE} component={GleitsichtTabelle}/>
                  <Route path={ERoutes.ARBEITSPLATZ_TABELLE} component={ArbeitsplatzPage}/>
                  <Route path={ERoutes.EINSTAERKEN_TABELLE} component={EinstaerkenPage}/>
              </Switch>
          </div>
          </Router>
     
      </ThemeProvider>
    </div>
  );
}

export default App;
