import { ETableEntity } from "../../definitions/app.definitions";
import { IStore } from "../store";

export const getTableDataForTableEntity = (store: IStore, entity: ETableEntity) => {
    const gleitsichtTable = store.gleitsichtTable;
    const arbeitsplatzTable = store.arbeitsplatzTable;
    const einstaerkenTable = store.einstaerkenTable;

    switch(entity) {
        case ETableEntity.GLEITSICHT:
            return {rate: gleitsichtTable.rate, selected: gleitsichtTable.selected};
        case ETableEntity.ARBEITSPLATZ:
            return {rate: arbeitsplatzTable.rate, selected: arbeitsplatzTable.selected};
        case ETableEntity.EINSTAERKEN:
            return {rate: einstaerkenTable.rate, selected: einstaerkenTable.selected};
        
        default:
            throw Error(`Table Entity ${entity} not configured`);
    }
}