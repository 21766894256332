import { createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { ETableEntity, ITableCallbacks } from "../../definitions/app.definitions";
import { getTableDataForTableEntity } from "../../redux/selectors/table.selectors";
import { IStore } from "../../redux/store";

const useStyles = makeStyles(
    theme => createStyles({
        flex: {
            flex: 1,
        },
        emptyCell: {
            flex: 1, 
            borderRight: '1px solid grey'
        },
        cell: {
            flex: 1, 
            paddingBottom: 8,
            paddingTop: 8,
            borderRight: '1px solid grey', 
            display: 'flex', 
            justifyContent: 'center', 
            textAlign: 'center', 
            alignItems: 'center', 
            flexDirection: 'column'
        },
        clickable: {
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
            backgroundColor: '#c8c8c8',
        },
        opacity: {
            opacity: 0.5,
        },
        selectedCell: {
            backgroundColor: theme.palette.primary.main,
        }
    })
)

interface IProps {
    item?: {
        title: string,
        price?: number,
        uvp?: number,
    },
    rowIndex?: number,
    columIndex?: number,
    callbacks: ITableCallbacks,
    tableEntity: ETableEntity,
}

const CustomTableCell: React.FC<IProps> = props => {
    const styles = useStyles();
    const tableData = useSelector((store: IStore) => getTableDataForTableEntity(store, props.tableEntity))
    const selected = tableData.selected;
    const rate = tableData.rate;

    if(!props.item) {
        return <div className={styles.emptyCell} />
    }

    const {title, price, uvp} = props.item;
    const priceToShow = price ? price / rate : 0;

    let cellStyle = !price ? styles.cell : `${styles.cell} ${styles.clickable}`
    let cellOpac = false;
    let selectedCell = false;

    if(price && (selected.columnIndex || selected.rowIndex)) {
        if(selected.rowIndex !== props.rowIndex && selected.columnIndex !== props.columIndex) {
            cellOpac = true;
        }

        if(selected.rowIndex === props.rowIndex && selected.columnIndex === props.columIndex) {
            selectedCell = true;
        }
    }

    if(cellOpac) {
        cellStyle = `${cellStyle} ${styles.opacity}`;
    }

    if(selectedCell){
        cellStyle = `${cellStyle} ${styles.selectedCell}`;
    }

    return (
        <div onClick={() => {
                if(price){
                    props.callbacks.setSelected(props.rowIndex as number, props.columIndex as number);
                    props.callbacks.setPrice(price);
                }
        }} className={cellStyle}>
            <Typography  className={styles.flex}>
                {title}
            </Typography>
                
            {
                price && 
                    <div className={styles.flex}>
                        {(priceToShow).toFixed(2)} - €
                    </div>
            }
            {
                uvp && 
                    <Typography variant={'caption'} className={styles.flex}>
                        Hersteller UVP {(uvp / rate).toFixed(2)}€
                    </Typography>
            }
    </div>
    );
}

export default CustomTableCell;