import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../redux/store';
import { IAufschlaegeState } from '../redux/aufschlaege/aufschlaege.reducer';
import { setAufschlagAction } from '../redux/aufschlaege/aufschlaege.actions';
import { Typography } from '@material-ui/core';
import { EAufschlagPreise } from '../definitions/app.definitions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(3),
    },
  }),
);

const Label = (label: string, price: number) => <div style={{display: 'flex', width: '100%'}}>
    <Typography  style={{flex:2, textAlign: 'start'}}>
        {label}
    </Typography>
    <Typography  style={{flex:1}}>
        {price} - €
    </Typography>
</div>

const CustomAufschlaege = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const aufschlaege = useSelector((store: IStore) => store.aufschlaege);
  const handleChange = (aufschlag: Partial<IAufschlaegeState>) => dispatch(setAufschlagAction(aufschlag));

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Aufschläge</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={aufschlaege.mdr} onChange={() => handleChange({mdr: !aufschlaege.mdr})} name="mdr" />}
            label={Label("MDR", EAufschlagPreise.MDR)}
          />
          <FormControlLabel
            control={<Checkbox checked={aufschlaege.antistatic} onChange={() => handleChange({antistatic: !aufschlaege.antistatic})} name="antistatic" />}
            label={Label("Antistatic", EAufschlagPreise.ANTISTATIC)}
          />
          <FormControlLabel
            control={<Checkbox checked={aufschlaege.phototrop} onChange={() => handleChange({phototrop: !aufschlaege.phototrop})} name="phototrop" />}
            label={Label("Phototrop", EAufschlagPreise.PHOTOTROP)}
          />
          <FormControlLabel
            control={<Checkbox checked={aufschlaege.verlausfarben} onChange={() => handleChange({verlausfarben: !aufschlaege.verlausfarben})} name="verlausfarben" />}
            label={Label("Verlaufsfarben / Sun & Fun", EAufschlagPreise.VERLAUFSFARBEN)}
          />
          <FormControlLabel
            control={<Checkbox checked={aufschlaege.standartfarben} onChange={() => handleChange({standartfarben: !aufschlaege.standartfarben})} name="standartfarben" />}
            label={Label("Standartfarben", EAufschlagPreise.STANDARTFARBEN)}
          />
          <FormControlLabel
            control={<Checkbox checked={aufschlaege.stammkundenrabatt} onChange={() => handleChange({stammkundenrabatt: !aufschlaege.stammkundenrabatt})} name="stammkundenrabatt" />}
            label={Label("Stammkundenrabatt", EAufschlagPreise.STAMMKUNDENRABATT)}
          />
          <FormControlLabel
            control={<Checkbox checked={aufschlaege.prisma} onChange={() => handleChange({prisma: !aufschlaege.prisma})} name="prisma" />}
            label={Label("Prisma", EAufschlagPreise.PRISMA)}
          />
          <FormControlLabel
            control={<Checkbox checked={aufschlaege.polarisierend} onChange={() => handleChange({polarisierend: !aufschlaege.polarisierend})} name="polarisierend" />}
            label={Label("Polarisierend", EAufschlagPreise.POLARISIEREND)}
          />
          <FormControlLabel
            control={<Checkbox checked={aufschlaege.sph} onChange={() => handleChange({sph: !aufschlaege.sph})} name="sph" />}
            label={Label("sph > 4.0 dpt", EAufschlagPreise.SPH)}
          />
          <FormControlLabel
            control={<Checkbox checked={aufschlaege.cyl} onChange={() => handleChange({cyl: !aufschlaege.cyl})} name="cyl" />}
            label={Label("cyl > 2.0 dpt", EAufschlagPreise.CYL)}
          />
          <FormControlLabel
            control={<Checkbox checked={aufschlaege.lotusbeschichtung} onChange={() => handleChange({lotusbeschichtung: !aufschlaege.lotusbeschichtung})} name="lotusbeschichtung" />}
            label={Label("Lotusbeschichtung", EAufschlagPreise.LOTUSBESCHICHTUNG)}
          />
          <FormControlLabel
            control={<Checkbox checked={aufschlaege.montage} onChange={() => handleChange({montage: !aufschlaege.montage})} name="montage" />}
            label={Label("Montage - Einarbeiten", EAufschlagPreise.MONTAGE)}
          />
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default CustomAufschlaege;