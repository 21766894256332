import { makeStyles, createStyles } from "@material-ui/core";
import React from "react";
import CustomSplitButton from "../../../components/CustomButton/CustomSplitButton";

const useStyles = makeStyles(
    createStyles({
        container: {
        },
        buttonGroup: {
            flex: 1,
        },
        button: {
            color: 'grey',
            marginRight: 8
        },
    })
)

interface IProps {
    setMatrix: (index: number) => void,
    options: string[],
}


const MatrixVergleichButtonGroup: React.FC<IProps> = props => {
    const styles = useStyles();

    return (
        <div className={styles.buttonGroup}>
            <CustomSplitButton options={props.options} onClick={index => props.setMatrix(index)}/>
        </div>
    )
}

export default MatrixVergleichButtonGroup;