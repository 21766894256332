import React from "react";
import CustomImage from "../../components/CustomImage";
import CustomHeadline from "../../components/CustomTypography/CustomHeadline"

const SehbereichPage: React.FC = () => {
    
    return (
        <div style={{marginTop: 16}}>
            <CustomHeadline>Perfektion in der Fertigung für den optimalen Einsatzbereich</CustomHeadline>
            <CustomImage style={{ marginTop: 16,}} path={'Sehbereiche.jpg'}/>
        </div>
    )
};

export default SehbereichPage;