import CustomImage from "../../components/CustomImage"
import CustomHeadline from "../../components/CustomTypography/CustomHeadline";

const BeschichtungsPage = () => {

    return (
        <div style={{marginTop: 16}}>
            <CustomHeadline>
                Das MultiSchicht-Design für Widerstandsfähige und leicht zu reinigende Glasoberflächen
            </CustomHeadline>
            <CustomImage path={'Beschichtungen.png'} />
        </div>
    )
}

export default BeschichtungsPage;