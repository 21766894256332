import { ICalculationItem } from "../../definitions/app.definitions";
import { ArbeitsplatzColumns, ArbeitsplatzDescriptions } from "../../pages/ArbeitsplatzPage/ArbeitsplatzPage";
import { getAufschlagPreisAndLabel } from "../../utils/component.utils";
import { IStore } from "../store";

export const getItemsForArbeitsplatzCalculation = (store: IStore) => {
    const aufschlaege = store.aufschlaege;
    const arbeitsplatzPreis = store.arbeitsplatzTable.price;
    const arbeitsplatzSelected = store.arbeitsplatzTable.selected;
    const arbeitsplatzRate= store.arbeitsplatzTable.rate;
    const firstItemLabel = `${ArbeitsplatzColumns[arbeitsplatzSelected.columnIndex]} ${ArbeitsplatzDescriptions[arbeitsplatzSelected.rowIndex]}`;
    const labelToUse = arbeitsplatzSelected.rowIndex < 2 ? '': firstItemLabel;

    let items: ICalculationItem[] = [{label: labelToUse, price: arbeitsplatzPreis/arbeitsplatzRate}]

    Object.values(aufschlaege).forEach(
        (value, index) => {
            if(value) {
                items.push(getAufschlagPreisAndLabel(index))
            }
        }
    )

    return items;
}