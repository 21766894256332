import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux'
import arbeitsplatzTableReducer, { IArbeitsplatzTableState } from './arbeitsplatzTable/arbeitsplatz.reducer';
import aufschlaegeReducer, { IAufschlaegeState } from './aufschlaege/aufschlaege.reducer';
import einstaerkenTableReducer, { IEinstaerkenTableState } from './einstaerkenTable/einstaerkenTable.reducer';
import gleitsichtTableReducer, { IGleitsichtTableState } from './gleitsichtTable/gleitsichtTable.reducer';

export interface IStore {
    gleitsichtTable: IGleitsichtTableState,
    aufschlaege: IAufschlaegeState,
    arbeitsplatzTable: IArbeitsplatzTableState,
    einstaerkenTable: IEinstaerkenTableState,
}

const rootReducer = combineReducers({
    gleitsichtTable: gleitsichtTableReducer,
    aufschlaege: aufschlaegeReducer,
    arbeitsplatzTable: arbeitsplatzTableReducer,
    einstaerkenTable: einstaerkenTableReducer,
});

const store = configureStore({
    reducer: rootReducer,
})

export default store;