import { ICalculationItem } from "../../definitions/app.definitions";
import { GleitsichtColumns, GleitsichtDescriptions } from "../../pages/GleitsichtTabelle/GleitsichtTabelle";
import { getAufschlagPreisAndLabel } from "../../utils/component.utils";
import { IStore } from "../store";

export const getItemsForGleitsichtCalculation = (store: IStore) => {
    const aufschlaege = store.aufschlaege;
    const gleitsichtPreis = store.gleitsichtTable.price;
    const gleitsichtSelected = store.gleitsichtTable.selected;
    const gleitsichtRate= store.gleitsichtTable.rate;
    const firstItemLabel = `${GleitsichtColumns[gleitsichtSelected.columnIndex]} ${GleitsichtDescriptions[gleitsichtSelected.rowIndex]}`;
    const labelToUse = gleitsichtSelected.rowIndex < 2 ? '': firstItemLabel;

    let items: ICalculationItem[] = [{label: labelToUse, price: gleitsichtPreis/gleitsichtRate}]

    Object.values(aufschlaege).forEach(
        (value, index) => {
            if(value) {
                items.push(getAufschlagPreisAndLabel(index))
            }
        }
    )

    return items;
}