import { ISetGleitsichtTablePriceAction, ISetGleitsichtTableRateAction, TAugenglueckAction } from "../../definitions/actions.definitions";
import { GLEITSICHT_TABELLE_SET_PRICE, GLEITSICHT_TABELLE_SET_RATE, GLEITSICHT_TABELLE_SET_SELECTED } from "./gleitsichtTable.actions";

export interface IGleitsichtTableState {
    rate: number,
    selected: {rowIndex: number, columnIndex: number},
    price: number,
}

export const gleitsichtTablePreloadedState = {
    selected: {rowIndex: 0, columnIndex: 0},
    rate: 1,
    price: 0,
}

const gleitsichtTableReducer = (state: IGleitsichtTableState = gleitsichtTablePreloadedState, action: TAugenglueckAction): IGleitsichtTableState => {
    switch(action.type) {
        case GLEITSICHT_TABELLE_SET_SELECTED: {
            return {
                ...state,
                selected: action.payload,
            }
        }
        case GLEITSICHT_TABELLE_SET_RATE: {
            const actionCast = action as ISetGleitsichtTableRateAction;
            return {
                ...state,
                rate: actionCast.payload,
            }
        }
        case GLEITSICHT_TABELLE_SET_PRICE: {
            const actionCast = action as ISetGleitsichtTablePriceAction;
            return {
                ...state,
                price: actionCast.payload,
            }
        }

        default: 
        return state;
    }
}

export default gleitsichtTableReducer;