import { Button, createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { ETableEntity, ICalculationItem, ITableCallbacks } from "../../definitions/app.definitions";
import CustomAufschlaege from "../CustomAufschlaege";
import CustomTable from "./CustomTable";
import CustomTableCalculation from "./CustomTableCalculation";
import CustomTableRatenButtons from "./CustomTableRatenButtons";

interface IProps {
    items: {title: string, body?: string, price?: number, uvp?: number}[][],
    columns: string[],
    descriptions: string[],
    calculationItems: ICalculationItem[],
    callbacks: ITableCallbacks,
    tableEntity: ETableEntity,
}

const useStyles = makeStyles(
    createStyles({
        container: {
            width: '95%', 
            margin: 'auto',
        },
        aufschlagContainer: {
            width: '100%', 
            margin: 'auto', 
            display: 'flex',
        },
        flex1: {
            flex: 1,
        },
        flex2: {
            flex: 2,
        },
        flex3: {
            flex: 3,
        },
        calculationTableContainer: {
            flex:3, 
            display: 'flex', 
            flexDirection: 'column', 
            paddingRight: 32,
        },
        buttonContainer: {
            display: 'flex', 
            marginTop: 16
        }
    })
)

const CustomTablePage:React.FC<IProps> = props => {
    const styles = useStyles();

    return (
        <div>
            <div className={styles.container}>
                <CustomTableRatenButtons setRaten={rate => props.callbacks.setRaten(rate)}/>
                <CustomTable 
                    tableEntity={props.tableEntity} 
                    callbacks={props.callbacks} 
                    columns={props.columns} 
                    items={props.items} 
                    description={props.descriptions} 
                />
            </div>
            <div className={styles.aufschlagContainer}>
                <div className={styles.flex2}>
                    <CustomAufschlaege />
                </div>
                <div className={styles.calculationTableContainer}>
                    <CustomTableCalculation items={props.calculationItems} />
                    <div className={styles.buttonContainer}>
                        <div className={styles.flex1} />
                        <Button 
                            className={styles.flex1} 
                            variant='contained' 
                            color='primary'
                        >
                            Bestellen
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomTablePage;