import MenuIcon from '@material-ui/icons/Menu';

export enum EIcons {
    Menu,
}

class IconFactory {
    static getIconComponent = (icon: EIcons, style={color: 'white'}) => {
        switch(icon) {
            case EIcons.Menu:
                return <MenuIcon style={style} />;

            default:
                throw Error(`Icon ${icon} was not configured for IconFactory.tsx`);
        }
    }
}

export default IconFactory;