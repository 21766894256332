import React from "react";
import CustomHeadline from "../../components/CustomTypography/CustomHeadline";
import WorkLifeTextContainer from "./components/WorkLifeTextContainer";
import WorkLife from "./WorkLife.jpg";

const WorkLifePage: React.FC = () => {
    return (
        <div style={{marginTop: 16}}>
            <div>
                <CustomHeadline>
                    Die passende Brille, für die optimale Work-Life-Balance
                </CustomHeadline>
            </div>

            <div style={{
                position: 'relative', 
                width: '100%',
                height: 700,
                backgroundImage: `url(${WorkLife})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center top',
            }}>
                <div style={{position: 'absolute', left: 0, width: '20%', marginLeft: '4%', marginTop: 32}}>
                    <WorkLifeTextContainer 
                        headline={'Gleitsicht'}
                        infos={[
                            'Allroundgläser für den Alltäglichen Einsatz',
                            'Für die beste Sicht im Fern- und Nahbereich',
                            'Ideal beim Autofahren, Hobby, Sport usw.'
                        ]}
                    />
                </div>
                <div style={{position: 'absolute', right: 0, width: '20%', marginRight: '4%', marginTop: 32}}>
                <WorkLifeTextContainer 
                    headline={'Arbeit'}
                    infos={[
                        'SpezialGläser für den Indoor-Einsatz',
                        'Entspanntes Nahsehen - vor allem im multimedialen Alltag und am ComputerArbeitsplatz',
                        'Grosses Blickfeld beim lesen mit exzellenter Raumwahrnehmung'
                    ]}
                />
                </div>
            </div>
            
        </div>
    );
}
/*
<div style={{display: 'flex', marginLeft: 32, marginRight: 32}}>
                <WorkLifeTextContainer 
                    headline={'Gleitsicht'}
                    infos={[
                        'Allroundgläser für den Alltäglichen Einsatz',
                        'Für die beste Sicht im Fern- und Nahbereich',
                        'Ideal beim Autofahren, Hobby, Sport usw.'
                    ]}
                />
                <CustomImage centered path={'WorkLife.jpg'} />
                <WorkLifeTextContainer 
                    headline={'Arbeit'}
                    infos={[
                        'SpezialGläser für den Indoor-Einsatz',
                        'Entspanntes Nahsehen - vor allem im multimedialen Alltag und am ComputerArbeitsplatz',
                        'Grosses Blickfeld beim lesen mit exzellenter Raumwahrnehmung'
                    ]}
                />
            </div>
            */

export default WorkLifePage;