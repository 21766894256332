import { createStyles, Divider, makeStyles, Typography } from "@material-ui/core"

interface IProps {
    items: {
        label: string, 
        price: number
    }[]
}

const useStyles = makeStyles(
    createStyles({
        itemRoot: {
            display: 'flex', 
            flex: 1, 
            width: '100%',
        },
        flex1: {
            flex: 1,
        },
        calculationRoot: {
            width: '100%', 
            marginTop: 32
        },
        border: {
            width: '100%',
            borderBottom: '2px solid black',
        }
    })
)

const CalculationItem = (label: string, price: number, index: number) => {
    const styles = useStyles();

    return (
        <div key={`calculationItem-${index}`} className={styles.itemRoot}>
            <div className={styles.flex1}>
                <Typography>
                    {label}
                </Typography>
            </div>
            <div className={styles.flex1}>
                <Typography>
                    {price.toFixed(2)} - €
                </Typography>
            </div>
            <Divider />
        </div>
    )
}

const CustomTableCalculation: React.FC<IProps> = props => {
    let gesamt = 0;
    const styles = useStyles();

    props.items.forEach(value => {
        gesamt = Number(gesamt) + Number(value.price);
    })

    return (
        <div className={styles.calculationRoot}>
            {
                props.items.map(
                    (value, index) => CalculationItem(value.label, value.price, index)
                )
            }
            <div className={styles.border}/>
            {CalculationItem('GESAMT', gesamt, 999)}
        </div>
    );
}

export default CustomTableCalculation;