import { makeStyles, createStyles } from "@material-ui/core";
import React, { useState } from "react";
import { EMatrix } from "../../../definitions/app.definitions";
import MatrixVergleichButtonGroup from "./MatrixVergleichButtonGroup";
import MatrixVergleichMaske from "./MatrixVergleichMaske/MatrixVergleichMaske";

const useStyles = makeStyles(
    createStyles({
        container: {
            width: '100%', 
            height: '100%'
        },
        flexContainer: {
            display: 'flex',
        },
        matrixContainer: {
            position: 'relative', 
            width: '100%', 
            height: '100%', 
            marginTop: 32
        }
    })
)

const options = [EMatrix.NONE, EMatrix.G2, EMatrix.G3, EMatrix.G4, EMatrix.G5, EMatrix.G6];

const MatrixVergleich = () => {
    const styles = useStyles();
    const [matrixLeft, setMatrixLeft] = useState(EMatrix.NONE);
    const [matrixRight, setMatrixRight] = useState(EMatrix.NONE);

    const setMatrix = (index: number, left: boolean) => {
        const matrixByIndex = options[index];

        if(left) {
            setMatrixLeft(matrixByIndex);
            return;
        }
        setMatrixRight(matrixByIndex);
    }

    return (
        <div className={styles.container}>
            <div className={styles.flexContainer}>
                <MatrixVergleichButtonGroup 
                    options={options}
                    setMatrix={index => setMatrix(index, true)}
                />
                <MatrixVergleichButtonGroup 
                    options={options}
                    setMatrix={index => setMatrix(index, false)}
                />
            </div>
            <div className={styles.matrixContainer}>
                <MatrixVergleichMaske matrixLeft={matrixLeft} matrixRight={matrixRight}/>
            </div>
        </div>
    )
}

export default MatrixVergleich;