import { Typography, ButtonGroup, Button, makeStyles, createStyles } from "@material-ui/core";
import React from "react";

interface IProps {
 setRaten: (rate: number) => void,
}
const useStyles = makeStyles(
    createStyles({
        container: {
            display: 'flex', 
            paddingLeft: '25%',
            marginBottom: 16, 
            marginTop: 16,
        },
        typo: {
            marginRight: 16
        },
        buttonGroup: {
            marginBottom: 8
        }
    })
)

const CustomTableRatenButtons: React.FC<IProps> = props => {
    const styles = useStyles();

    return (
        <div className={styles.container}>
            <Typography variant={'h5'} className={styles.typo}>Raten: </Typography>
            <ButtonGroup className={styles.buttonGroup} variant="contained" color="primary" >
                <Button onClick={() => props.setRaten(1)}>1</Button>
                <Button onClick={() => props.setRaten(6)}>6</Button>
                <Button onClick={() => props.setRaten(12)}>12</Button>
                <Button onClick={() => props.setRaten(18)}>18</Button>
                <Button onClick={() => props.setRaten(24)}>24</Button>
            </ButtonGroup>
        </div>
    )
}

export default CustomTableRatenButtons;