import React from "react";

export interface IAnchorHook {
    anchorEl: null | any, 
    openMenu: (event: any) => void, 
    closeMenu: () => void,
}

export const useCustomAnchorHook = (): IAnchorHook => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const openMenu = (event: any) => {
      setAnchorEl(event.currentTarget);
    };
  
    const closeMenu = () => {
      setAnchorEl(null);
    };

    return {anchorEl, openMenu, closeMenu};
};