import { createStyles, makeStyles } from "@material-ui/core";

interface IProps {
    id?: string,
    path: string,
    centered?: boolean,
    style?: any,
}

const useStyles = makeStyles(
    createStyles({
        image: {
            maxWidth: '100%',
            height: 'auto',
        },
        centeredImage: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '50%',
            height: '50%',
        }
    })
)

const CustomImage: React.FC<IProps> = props => {
    const styles = useStyles();
    const classToUse = !props.centered ? styles.image : `${styles.image} ${styles.centeredImage}`

    return (
        <img alt='' id={props.id} style={props.style} className={classToUse} src={process.env.PUBLIC_URL + props.path} /> 
    )
};

export default CustomImage;