import { ISetEinstaerkenTablePriceAction, ISetEinstaerkenTableRateAction, TAugenglueckAction } from "../../definitions/actions.definitions";
import { EINSTAERKEN_TABELLE_SET_SELECTED, EINSTAERKEN_TABELLE_SET_RATE, EINSTAERKEN_TABELLE_SET_PRICE } from "./einstaerkenTable.actions";

export interface IEinstaerkenTableState {
    rate: number,
    selected: {rowIndex: number, columnIndex: number},
    price: number,
}

export const einstaerkenTablePreloadedState = {
    selected: {rowIndex: 0, columnIndex: 0},
    rate: 1,
    price: 0,
}

const arbeitsplatzTableReducer = (state: IEinstaerkenTableState = einstaerkenTablePreloadedState, action: TAugenglueckAction): IEinstaerkenTableState => {
    switch(action.type) {
        case EINSTAERKEN_TABELLE_SET_SELECTED: {
            return {
                ...state,
                selected: action.payload,
            }
        }
        case EINSTAERKEN_TABELLE_SET_RATE: {
            const actionCast = action as ISetEinstaerkenTableRateAction;
            return {
                ...state,
                rate: actionCast.payload,
            }
        }
        case EINSTAERKEN_TABELLE_SET_PRICE: {
            const actionCast = action as ISetEinstaerkenTablePriceAction;
            return {
                ...state,
                price: actionCast.payload,
            }
        }

        default: 
        return state;
    }
}

export default arbeitsplatzTableReducer;