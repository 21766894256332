import { Menu } from "@material-ui/core";
import React from "react"
import { IAnchorHook } from "../utils/hooks.utils";

const CustomMenu: React.FC<IAnchorHook> = props => {
    const {anchorEl, closeMenu} = props;
  
    return (
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
        >
            {props.children}
        </Menu>
    )
};

export default CustomMenu;