import { EAufschlagPreise } from "../definitions/app.definitions";

export const getAufschlagPreisAndLabel = (index: number) => {
    switch(index) {
        case 0:
            return {label: "MDR", price: EAufschlagPreise.MDR}
        case 1:
            return {label: "Antistatic", price: EAufschlagPreise.ANTISTATIC}
        case 2:
            return {label: "Phototrop", price: EAufschlagPreise.PHOTOTROP}
        case 3:
            return {label: "Verlaufsfarben / Sun & Fun", price: EAufschlagPreise.VERLAUFSFARBEN}
        case 4:
            return {label: "Standartfarben", price: EAufschlagPreise.STANDARTFARBEN}
        case 5:
            return {label: "Stammkundenrabatt", price: EAufschlagPreise.STAMMKUNDENRABATT}
        case 6:
            return {label: "Prisma", price: EAufschlagPreise.PRISMA}
        case 7:
            return {label: "Polarisierend", price: EAufschlagPreise.POLARISIEREND}
        case 8:
            return {label: "sph > 4.0 dpt", price: EAufschlagPreise.SPH}
        case 9:
            return {label: "cyl > 2.0 dpt", price: EAufschlagPreise.CYL}
        case 10:
            return {label: "Lotusbeschichtung", price: EAufschlagPreise.LOTUSBESCHICHTUNG}
        case 11:
            return {label: "Montage - Einarbeiten", price: EAufschlagPreise.MONTAGE}
        default:
            throw Error('Kein Aufschlag Preis konfiguriert für index: ' + index);
    }
}