import { ISetAufschlagAction, TAugenglueckAction } from "../../definitions/actions.definitions";
import { AUSCHLAEGE_SET } from "./aufschlaege.actions";

export interface IAufschlaegeState {
    mdr: boolean,
    antistatic: boolean,
    phototrop: boolean,
    verlausfarben: boolean,
    standartfarben: boolean,
    stammkundenrabatt: boolean,
    prisma: boolean,
    polarisierend: boolean,
    sph: boolean,
    cyl: boolean,
    lotusbeschichtung: boolean,
    montage: boolean,
}

export const aufschlagePreloadedState = {
    mdr: false,
    antistatic: false,
    phototrop: false,
    verlausfarben: false,
    standartfarben: false,
    stammkundenrabatt: false,
    prisma: false,
    polarisierend: false,
    sph: false,
    cyl: false,
    lotusbeschichtung: false,
    montage: false,
}

const aufschlaegeReducer = (state: IAufschlaegeState = aufschlagePreloadedState, action: TAugenglueckAction): IAufschlaegeState => {
    switch(action.type) {
        case AUSCHLAEGE_SET: {
            const actionCast = action as ISetAufschlagAction;
            
            return {
                ...state,
                ...actionCast.payload
            }
        }

        default: 
        return state;
    }
}

export default aufschlaegeReducer;