import React from "react"
import CustomImage from "../../components/CustomImage"

const StartPage: React.FC = () => {
    return (
        <div>
            <CustomImage centered path={'augengluek.jpg'} />
            <CustomImage centered path={'AugenGlueckBanner.jpg'} />
        </div>
    )
}

export default StartPage;