import { createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import { orange } from '@material-ui/core/colors';

export const ORANGE = orange[500];

export const theme = createMuiTheme({
    typography: {
        allVariants: {
            color: 'grey',
        },
        fontSize: 12,
    },
    overrides: {
        MuiFormControl: {
            root: {
                width: '100%',
            },
        },
        MuiFormControlLabel: {
            label: {
                width: '100%'
            }
        }
    },
    palette: {
        primary: {
            main: ORANGE,
        },
        secondary: {
            main: green[500],
        },
    },
});
