import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTablePage from "../../components/CustomTable/CustomTablePage";
import { ETableEntity } from "../../definitions/app.definitions";
import { setEinstaerkenRate, setEinstaerkenTablePrice, setEinstaerkenTableSelectedAction } from "../../redux/einstaerkenTable/einstaerkenTable.actions";
import { getItemsForEinstaerkenCalculation } from "../../redux/selectors/einstaerkenTabelle.selectors";

export const EinstaerkenColumns = ['Premium', 'Asphärisch', 'Top Produkt 1', 'Top Produkt 2', 'Top Einstieg'];
export const EinstaerkenDescriptions = ['', '', 'Veredelung', 'Brechungsindex 1,74', 'Brechungsindex 1,67', 'Brechungsindex 1,60', 'Brechungsindex 1,50'];

const EinstaerkenTabelle = () => {
    const items = [
        [
            {
                title: 'Made in Germany, inkl. MDR!',
            },
            {
                title: 'Made in Germany',
            },
            {
                title: 'Made in Germany',
                body: '3 Jahre Garantie'
            },
            {
                title: 'Made in Japan',
            },
            {
                title: 'Made in Japan',
            },
        ],
        [
            {
                title: '1/100 dpt',
                body: '3 Jahre Garantie!'
            },
            {
                title: '1/8 dpt',
                body: '3 Jahre Garantie!'
            },
            {
                title: '1/8 dpt',
                body: 'Beste Oberfläche'
            },
            {
                title: '3 Jahre Garantie!'
            },
            {
                title: '2 Jahre Garantie'
            },
        ],
        [
            {
                title: 'Super ET, Lotus, antistatic'
            },
            {
                title: 'Super ET, Lotus, antistatic'
            },
            {
                title: 'Orgalit Care - Bester Augenschutz'
            },
            {
                title: 'Super ET, Lotus'
            },
            {
                title: 'Super ET, Lotus'
            },
        ],
        [
            {
                title: 'Augenglück Plus',
                price: 599,
                uvp: 708
            },
            {
                title: 'Augenglück Plus',
                price: 499,
                uvp: 554
            },
        ],
        [
            {
                title: 'Augenglück Plus',
                price: 459,
                uvp: 564
            },
            {
                title: 'Augenglück Plus',
                price: 399,
                uvp: 466
            },
            {
                title: 'Augenglück Plus',
                price: 379,
                uvp: 569
            },
            {
                title: 'Augenglück Plus',
                price: 339,
                uvp: 518
            },
            {
                title: 'Augenglück Plus',
                price: 267,
            },
        ],
        [
            {
                title: 'Augenglück Plus',
                price: 439,
                uvp: 536
            },
            {
                title: 'Augenglück Plus',
                price: 369,
                uvp: 438
            },
            {
                title: 'Augenglück Plus',
                price: 329,
                uvp: 442
            },
            {
                title: 'Augenglück Plus',
                price: 289,
                uvp: 418
            },
            {
                title: 'Augenglück Plus',
                price: 192,
                uvp: 249
            },
        ],
        [
            {
                title: 'Augenglück Plus',
                price: 409,
                uvp: 494
            },
            {
                title: '',
            },
            {
                title: 'Augenglück Plus',
                price: 279,
                uvp: 329
            },
            {
                title: 'Augenglück Plus',
                price: 259,
                uvp: 376
            },
            {
                title: 'Augenglück Plus',
                price: 144,
                uvp: 173
            },
        ]
    ]
    const calculationItems = useSelector(getItemsForEinstaerkenCalculation);
    const dispatch = useDispatch();

    return (
        <CustomTablePage 
            items={items}
            columns={EinstaerkenColumns}
            descriptions={EinstaerkenDescriptions}
            calculationItems={calculationItems}
            tableEntity={ETableEntity.EINSTAERKEN}
            callbacks={{
                setRaten: rate => dispatch(setEinstaerkenRate(rate)),
                setPrice: price => dispatch(setEinstaerkenTablePrice(price)),
                setSelected: (rowIndex, columnIndex) => dispatch(setEinstaerkenTableSelectedAction(rowIndex, columnIndex))
            }}
        />
    )
}

export default EinstaerkenTabelle;