export const EINSTAERKEN_TABELLE_SET_SELECTED = 'EINSTAERKEN_TABELLE_SET_SELECTED';
export const EINSTAERKEN_TABELLE_SET_RATE = 'EINSTAERKEN_TABELLE_SET_RATE';
export const EINSTAERKEN_TABELLE_SET_PRICE = 'EINSTAERKEN_TABELLE_SET_PRICE';

export const setEinstaerkenTableSelectedAction = (rowIndex: number, columnIndex: number) => ({
    type: EINSTAERKEN_TABELLE_SET_SELECTED,
    payload: {rowIndex, columnIndex},
})

export const setEinstaerkenRate = (rate: number) => ({
    type: EINSTAERKEN_TABELLE_SET_RATE,
    payload: rate,
})

export const setEinstaerkenTablePrice = (price: number) => ({
    type: EINSTAERKEN_TABELLE_SET_PRICE,
    payload: price.toFixed(2),
})