export const GLEITSICHT_TABELLE_SET_SELECTED = 'GLEITSICHT_TABELLE_SET_SELECTED';
export const GLEITSICHT_TABELLE_SET_RATE = 'GLEITSICHT_TABELLE_SET_RATE';
export const GLEITSICHT_TABELLE_SET_PRICE = 'GLEITSICHT_TABELLE_SET_PRICE';

export const setGleitsichtTableSelectedAction = (rowIndex: number, columnIndex: number) => ({
    type: GLEITSICHT_TABELLE_SET_SELECTED,
    payload: {rowIndex, columnIndex},
})

export const setGleitsichtRate = (rate: number) => ({
    type: GLEITSICHT_TABELLE_SET_RATE,
    payload: rate,
})

export const setGleitsichtTablePrice = (price: number) => ({
    type: GLEITSICHT_TABELLE_SET_PRICE,
    payload: price.toFixed(2),
})