import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { ITableItem } from "../../definitions/app.definitions";

const useStyles = makeStyles(
    createStyles({
        row: {
            flex: 1, 
            display: 'flex', 
            height: '10%', 
            borderBottom: '1px solid grey',
            marginRight: 1,
        },
        description: {
            flex: 1, 
            color: 'white', 
            backgroundColor: 'grey', 
            display: 'flex', 
            justifyContent: 'center', 
            textAlign: 'center', 
            alignItems: 'center',
            minWidth: 127,
        }
    })
)

interface IProps {
    description: string,
    item?: ITableItem,
}

const CustomTableRow: React.FC<IProps> = props => {
    const styles = useStyles();

    return (
        <div className={styles.row}>
            <Typography className={styles.description}>
                {props.description}
            </Typography>
            {props.children}
        </div>
    )
}

export default CustomTableRow;