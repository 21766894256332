import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTablePage from "../../components/CustomTable/CustomTablePage";
import { ETableEntity } from "../../definitions/app.definitions";
import { setArbeitsplatzRate, setArbeitsplatzTablePrice, setArbeitsplatzTableSelectedAction } from "../../redux/arbeitsplatzTable/arbeitsplatz.actions";
import { getItemsForArbeitsplatzCalculation } from "../../redux/selectors/arbeitsplatzTabelle.selectors";

export const ArbeitsplatzColumns = ['Raumgläser 1', 'Raumgläser 2', 'Raumgläser 3', 'Simple Office'];
export const ArbeitsplatzDescriptions = ['Zentrierhöhe', 'Oberflächen-Veredelung', 'Brechungsindex 1,67', 'Brechungsindex 1,60', 'Brechungsindex 1,50'];

const ArbeitsplatzPage = () => {
    const items = [
        [
            {
                title: 'Beste Qualität für höchste Anforderungen',
            },
            {
                title: 'made in Germany',
            },
            {
                title: 'made in Japan',
            },
            {
                title: '15 mm',
            },
        ],
        [
            {
                title: 'Super ET, Lotus, antistatic 21 mm'
            },
            {
                title: 'Super ET, Lotus, antistatic 21 mm'
            },
            {
                title: 'Super ET, Lotus, antistatic 21 mm'
            },
            {
                title: 'Super ET, Lotus'
            },
        ],
        [
            {
                title: 'Augenglück Plus',
                price: 629,
                uvp: 888
            },
        ],
        [
            {
                title: 'Augenglück Plus',
                price: 589,
                uvp: 816
            },
            {
                title: 'Augenglück Plus',
                price: 549,
                uvp: 606
            },
            {
                title: 'Augenglück Plus',
                price: 499,
                uvp: 680
            },
            {
                title: 'Augenglück Plus',
                price: 329,
                uvp: 474
            },
        ],
        [
            {
                title: 'Augenglück Plus',
                price: 529,
                uvp: 756
            },
            {
                title: '',
            },
            {
                title: 'Augenglück Plus',
                price: 459,
                uvp: 632
            },
            {
                title: 'Augenglück Plus',
                price: 249,
                uvp: 416
            },
        ],
    ]
    const calculationItems = useSelector(getItemsForArbeitsplatzCalculation);
    const dispatch = useDispatch();

    return (
        <CustomTablePage 
            items={items}
            columns={ArbeitsplatzColumns}
            descriptions={ArbeitsplatzDescriptions}
            calculationItems={calculationItems}
            tableEntity={ETableEntity.ARBEITSPLATZ}
            callbacks={{
                setRaten: rate => dispatch(setArbeitsplatzRate(rate)),
                setPrice: price => dispatch(setArbeitsplatzTablePrice(price)),
                setSelected: (rowIndex, columnIndex) => dispatch(setArbeitsplatzTableSelectedAction(rowIndex, columnIndex))
            }}
        />
    )
}

export default ArbeitsplatzPage;