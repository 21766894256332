import None_left from "./images/none_left_borderless.jpg";
import None_right from "./images/none_right_borderless.jpg";
import g2_left from "./images/g2_left_borderless.jpg";
import g2_right from "./images/g2_right_borderless.jpg";
import g3_left from "./images/g3_left_borderless.jpg";
import g3_right from "./images/g3_right_borderless.jpg";
import g4_left from "./images/g4_left_borderless.jpg";
import g4_right from "./images/g4_right_borderless.jpg";
import g5_right from "./images/g5_right_borderless.jpg";
import g5_left from "./images/g5_left_borderless.jpg";
import g6_right from "./images/g6_right_borderless.jpg";
import g6_left from "./images/g6_left_borderless.jpg";
import Brille from "./images/Brille.png";
import { EMatrix } from "../../../../definitions/app.definitions";

interface IProps {
    matrixLeft: EMatrix,
    matrixRight: EMatrix,
}

const getPathForMatrix = (matrix: EMatrix, left: boolean) => {
    switch (matrix) {
        case EMatrix.NONE: {
            if(left) {
                return None_left;
            }

            return None_right;
        }
        case EMatrix.G2: {
            if(left) {
                return g2_left;
            }

            return g2_right;
        }
        case EMatrix.G3: {
            if(left) {
                return g3_left;
            }

            return g3_right;
        }
        case EMatrix.G4: {
            if(left) {
                return g4_left;
            }

            return g4_right;
        }
        case EMatrix.G5: {
            if(left) {
                return g5_left;
            }

            return g5_right;
        }
        case EMatrix.G6: {
            if(left) {
                return g6_left;
            }

            return g6_right;
        }
    
        default:
            break;
    }
}

const MatrixVergleichMaske:React.FC<IProps> = props => {
    const pathLeft = getPathForMatrix(props.matrixLeft, true);
    const pathRight = getPathForMatrix(props.matrixRight, false);

    return (
        <div style={{
            margin: 'auto',
            position: 'relative',
            display: 'block',
            width: '100%',
            maxHeight: 600,
            height: '100%',
            overflow: 'visible'
        }}>
            <div style={{
                backgroundColor: 'white',
                width: '10%',
                height: '100%',
                position: 'absolute',
                left: 0,
                zIndex: 1,
            }}/>
            <div style={{
                backgroundColor: 'white',
                width: '10%',
                height: '100%',
                position: 'absolute',
                right: 0,
                zIndex: 1,
            }}/>
            <div 
            style={{
                position: 'absolute',
                zIndex: 1,
                width: '90%',
                height: '100%',
                backgroundImage: `url(${Brille})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center top',
                left: 0,
                right: 0,
                margin: 'auto'
                }}
            />
            <div style={{
                position: 'absolute',
                zIndex: 0,
                display: 'table',
                width: '96%',
                height: '100%',
                left: 0,
                right: 12,
                margin: 'auto'
            }}>
                <div style={{
                    display: 'table-cell',
                    width: '50%',
                    height: '100%',
                    backgroundImage: `url(${pathLeft})`,
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center top',
                }} />
                <div style={{
                    display: 'table-cell',
                    width: '50%',
                    height: '100%',
                    backgroundImage: `url(${pathRight})`,
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center top',
                }} />
            </div>
        </div>
    )
}

export default MatrixVergleichMaske;