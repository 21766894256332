import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTablePage from "../../components/CustomTable/CustomTablePage";
import { ETableEntity } from "../../definitions/app.definitions";
import { setGleitsichtRate, setGleitsichtTablePrice, setGleitsichtTableSelectedAction } from "../../redux/gleitsichtTable/gleitsichtTable.actions";
import { getItemsForGleitsichtCalculation } from "../../redux/selectors/gleitsichtTabelle.selectors";

export const GleitsichtColumns = ['G6', 'G5', 'G4', 'G3', 'G2'];
export const GleitsichtDescriptions = ['Zentrierhöhe', 'Oberflächen-Veredelung', 'Brechungsindex 1,74', 'Brechungsindex 1,67', 'Brechungsindex 1,60', 'Brechungsindex 1,50'];

const GleitsichtTabelle = () => {
    const items = [
        [
            {
                title: 'reg - med - short',
                body: '14-21 in mm Stufen'
            },
            {
                title: 'reg - med - short',
                body: '20 / 17 / 15'
            },
            {
                title: 'reg - med - short',
                body: '20 / 17 / 15'
            },
            {
                title: 'reg - short',
                body: '20 / 15'
            },
            {
                title: 'reg - short',
                body: '21 / 15'
            },
        ],
        [
            {
                title: 'Super ET, Lotus, antistatic'
            },
            {
                title: 'Super ET, Lotus, antistatic'
            },
            {
                title: 'Super ET, Lotus, antistatic'
            },
            {
                title: 'Super ET, Lotus'
            },
            {
                title: 'Super ET, Lotus'
            },
        ],
        [
            {
                title: 'Augenglück Plus',
                price: 999,
                uvp: 1279
            },
            {
                title: 'Augenglück Plus',
                price: 939,
                uvp: 1191
            },
        ],
        [
            {
                title: 'Augenglück Plus',
                price: 929,
                uvp: 1179
            },
            {
                title: 'Augenglück Plus',
                price: 879,
                uvp: 1064
            },
            {
                title: 'Augenglück Plus',
                price: 812,
                uvp: 954
            },
            {
                title: 'Augenglück Plus',
                price: 599,
                uvp: 948
            },
        ],
        [
            {
                title: 'Augenglück Plus',
                price: 889,
                uvp: 1099
            },
            {
                title: 'Augenglück Plus',
                price: 832,
                uvp: 1011
            },
            {
                title: 'Augenglück Plus',
                price: 729,
                uvp: 875
            },
            {
                title: 'Augenglück Plus',
                price: 559,
                uvp: 869
            },
            {
                title: 'Augenglück Plus',
                price: 469,
                uvp: 594
            },
        ],
        [
            {
                title: 'Augenglück Plus',
                price: 832,
                uvp: 1031
            },
            {
                title: 'Augenglück Plus',
                price: 788,
                uvp: 943
            },
            {
                title: 'Augenglück Plus',
                price: 669,
                uvp: 765
            },
            {
                title: 'Augenglück Plus',
                price: 469,
                uvp: 799
            },
            {
                title: 'Augenglück Plus',
                price: 429,
                uvp: 525
            },
        ]
    ]
    const calculationItems = useSelector(getItemsForGleitsichtCalculation);
    const dispatch = useDispatch();

    return (
        <CustomTablePage 
            items={items}
            columns={GleitsichtColumns}
            descriptions={GleitsichtDescriptions}
            calculationItems={calculationItems}
            tableEntity={ETableEntity.GLEITSICHT}
            callbacks={{
                setRaten: rate => dispatch(setGleitsichtRate(rate)),
                setPrice: price => dispatch(setGleitsichtTablePrice(price)),
                setSelected: (rowIndex, columnIndex) => dispatch(setGleitsichtTableSelectedAction(rowIndex, columnIndex))
            }}
        />
    )
}

export default GleitsichtTabelle;