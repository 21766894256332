import { ICalculationItem } from "../../definitions/app.definitions";
import { EinstaerkenColumns, EinstaerkenDescriptions } from "../../pages/EinstaerkenPage/EinstaerkenPage";
import { getAufschlagPreisAndLabel } from "../../utils/component.utils";
import { IStore } from "../store";

export const getItemsForEinstaerkenCalculation = (store: IStore) => {
    const aufschlaege = store.aufschlaege;
    const einstaerkenPreis = store.einstaerkenTable.price;
    const einstaerkenSelected = store.einstaerkenTable.selected;
    const einstaerkenRate= store.einstaerkenTable.rate;
    const firstItemLabel = `${EinstaerkenColumns[einstaerkenSelected.columnIndex]} ${EinstaerkenDescriptions[einstaerkenSelected.rowIndex]}`;
    const labelToUse = einstaerkenSelected.rowIndex < 2 ? '': firstItemLabel;

    let items: ICalculationItem[] = [{label: labelToUse, price: einstaerkenPreis/einstaerkenRate}]

    Object.values(aufschlaege).forEach(
        (value, index) => {
            if(value) {
                items.push(getAufschlagPreisAndLabel(index))
            }
        }
    )

    return items;
}