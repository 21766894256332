import { Typography } from "@material-ui/core";
import React from "react";

const CustomHeadline:React.FC = props => {

    return (
        <Typography variant={'h4'}>
            {props.children}
        </Typography>
    )
};

export default CustomHeadline;