import { createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { ETableEntity, ITableCallbacks } from "../../definitions/app.definitions";
import CustomTableCell from "./CustomTableCell";
import CustomTableRow from "./CustomTableRow";

interface IProps {
   items: any[][],
   descriptions: string[],
   columnCount: number,
   callbacks: ITableCallbacks,
   tableEntity: ETableEntity,
}

const useStyles = makeStyles(
    createStyles({
        body:{
            display: 'flex', 
            flex: 1, 
            flexDirection: 'column',  
            maxHeight: '60%', 
            borderTop: '1px solid grey'
        }
    })
)

const CustomTableBody: React.FC<IProps> = props => {
    const styles = useStyles();
    const tableBody: any[] = [];

    props.items.forEach((rowItems: any[], index: number) => {
        const rowIndex = index;
        let columnIndex = 0;
        const descriptionForRow = props.descriptions[index];

        const items = rowItems.map((item: any, index: number) => {
                columnIndex = index;

                return (
                    <CustomTableCell 
                        key={`${rowIndex}-cell-${columnIndex}`}
                        tableEntity={props.tableEntity}
                        callbacks={props.callbacks} 
                        item={item} 
                        rowIndex={rowIndex} 
                        columIndex={columnIndex} 
                    />
                )
            })

        const emptyDivs = [];
        const delta = props.columnCount - items.length;

        if(delta !== 0) {
            let i = 0;
            for (i; i < delta; i++) {
                emptyDivs.push(<CustomTableCell key={`${rowIndex}-cell-${columnIndex}-${i}`} tableEntity={props.tableEntity} callbacks={props.callbacks}/>)    
            }
        }

        tableBody.push(<CustomTableRow key={rowIndex} description={descriptionForRow}>{items}{emptyDivs}</CustomTableRow>);
    });

    return (
        <div className={styles.body}>
            {tableBody}
        </div>
    )
}

export default CustomTableBody;