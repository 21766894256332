import { Typography } from "@material-ui/core";

interface IProps {
    headline: string,
    infos: string[],
}

const WorkLifeTextContainer:React.FC<IProps> = props => {
    const typographies = props.infos.map(
        infoString => 
            <Typography 
                style={{marginTop: 12}} 
                variant={'body2'}
            >
                {infoString}
            </Typography>
        )

    return (
        <div style={{flex: 1}}>
            <Typography variant={'h6'}>
                {props.headline}
            </Typography>
            {typographies}
        </div>
    )
}

export default WorkLifeTextContainer;