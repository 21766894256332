import React from "react";
import CustomHeadline from "../../components/CustomTypography/CustomHeadline"
import MatrixVergleich from "./components/MatrixVergleich";

const MatrixPage = () => {
    return (
        <div style={{marginTop: 16, height: '100%'}}>
            <CustomHeadline>
                Matrix-Vergleich
            </CustomHeadline>
            <MatrixVergleich />
        </div>
    )
}

export default MatrixPage;