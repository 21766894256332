export const ARBEITSPLATZ_TABELLE_SET_SELECTED = 'ARBEITSPLATZ_TABELLE_SET_SELECTED';
export const ARBEITSPLATZ_TABELLE_SET_RATE = 'ARBEITSPLATZ_TABELLE_SET_RATE';
export const ARBEITSPLATZ_TABELLE_SET_PRICE = 'ARBEITSPLATZ_TABELLE_SET_PRICE';

export const setArbeitsplatzTableSelectedAction = (rowIndex: number, columnIndex: number) => ({
    type: ARBEITSPLATZ_TABELLE_SET_SELECTED,
    payload: {rowIndex, columnIndex},
})

export const setArbeitsplatzRate = (rate: number) => ({
    type: ARBEITSPLATZ_TABELLE_SET_RATE,
    payload: rate,
})

export const setArbeitsplatzTablePrice = (price: number) => ({
    type: ARBEITSPLATZ_TABELLE_SET_PRICE,
    payload: price.toFixed(2),
})