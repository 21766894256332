import { ISetArbeitsplatzTablePriceAction, ISetArbeitsplatzTableRateAction, TAugenglueckAction } from "../../definitions/actions.definitions";
import { ARBEITSPLATZ_TABELLE_SET_SELECTED, ARBEITSPLATZ_TABELLE_SET_RATE, ARBEITSPLATZ_TABELLE_SET_PRICE } from "./arbeitsplatz.actions";

export interface IArbeitsplatzTableState {
    rate: number,
    selected: {rowIndex: number, columnIndex: number},
    price: number,
}

export const arbeitsplatzTablePreloadedState = {
    selected: {rowIndex: 0, columnIndex: 0},
    rate: 1,
    price: 0,
}

const arbeitsplatzTableReducer = (state: IArbeitsplatzTableState = arbeitsplatzTablePreloadedState, action: TAugenglueckAction): IArbeitsplatzTableState => {
    switch(action.type) {
        case ARBEITSPLATZ_TABELLE_SET_SELECTED: {
            return {
                ...state,
                selected: action.payload,
            }
        }
        case ARBEITSPLATZ_TABELLE_SET_RATE: {
            const actionCast = action as ISetArbeitsplatzTableRateAction;
            return {
                ...state,
                rate: actionCast.payload,
            }
        }
        case ARBEITSPLATZ_TABELLE_SET_PRICE: {
            const actionCast = action as ISetArbeitsplatzTablePriceAction;
            return {
                ...state,
                price: actionCast.payload,
            }
        }

        default: 
        return state;
    }
}

export default arbeitsplatzTableReducer;